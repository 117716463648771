import { log } from 'cf-common/src/logger';
import { showSomethingWentWrongToaster } from '@services/MessageService';
import { LOCAL_DATA_QUERY, SET_AUTH_MUTATION } from '@utils/AuthFlow/queries';
import client from '../../../common/services/ApolloService';

export const setAuthToken = async (token: string) => {
  try {
    await client.mutate({
      mutation: SET_AUTH_MUTATION,
      variables: { token },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: LOCAL_DATA_QUERY }],
    });
  } catch (error) {
    log.warn({ error, msg: 'Error while setting token on login page' });
    showSomethingWentWrongToaster();
  }
};
