import { sendEvent } from '@utils/Analytics';
import { log } from 'cf-common/src/logger';
import { PermissionGroup } from '@utils/Permissions';

export interface SendInitialEventsAndLogsArgs {
  isAutoLogin?: boolean;
  interruptedHref: string;
  reason: string;
  permissionGroup?: PermissionGroup;
}

export const sendInitialEventsAndLogs = ({
  reason,
  isAutoLogin,
  interruptedHref,
  permissionGroup,
}: SendInitialEventsAndLogsArgs) => {
  if (
    isAutoLogin &&
    permissionGroup === PermissionGroup.full_and_marketing &&
    !interruptedHref &&
    !reason
  ) {
    sendEvent({
      category: 'onboarding',
      action: 'start',
    });
  }

  if (!isAutoLogin) {
    sendEvent({
      category: 'welcome',
      action: 'seen',
      propertyBag: {
        referrer: document.referrer,
      },
    });
  }

  log.warn({
    msg: `Mount login page`,
    data: {
      reason,
      isAutoLogin,
      interruptedHref,
    },
  });
};
