import cn from 'classnames';
import React from 'react';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { ButtonLikeLoader } from './ButtonLikeLoader';
import { ReactComponent as GoogleIcon } from './GoogleIcon.svg';
import * as css from './OauthButtons.css';

interface GoogleButtonProps {
  title: string;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
}

export const GoogleButton: React.FC<GoogleButtonProps> = ({
  title,
  loading,
  onClick,
  disabled,
}) => {
  if (loading) {
    return <ButtonLikeLoader />;
  }

  return (
    <Button
      className={cn(css.button, css.google)}
      disabled={disabled}
      intent="primary"
      onClick={onClick}
      data-testid="google-sign-button"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <GoogleIcon />
        <div className={css.googleLabel}>{title}</div>
      </Flex>
    </Button>
  );
};
