import React, { useEffect, useState } from 'react';
import { AuthLayout } from '../../layout/AuthLayout';
import { RouteComponentProps } from 'react-router-dom';
import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { OriginalAccountType } from '@globals';
import { locationToUrl } from 'cf-common/src/utils/URL/locationToUrl';
import { setFirstSessionDone } from '../../../BotPage/KeywordsPage/FirstSessionPage/utils/setFirstSessionDone';
import { redirectToDefaultTab } from '@utils/redirectToBot';
import { removeGlobalLoader } from 'cf-common/src/utils/removeGlobalLoader';
import { log } from 'cf-common/src/logger';
import { ServiceMessageType, toaster } from '@services/MessageService';
import i18next from 'i18next';
import { AuthButtons } from '../../components/AuthButtons';
import { Invalid } from './Invalid/Invalid';
import { ReactComponent as Bot } from './bot.svg';
import * as css from './Invite.css';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { updateWorkspaceAvailableCache } from '@utils/Data/Workspaces/updateWorkspaceAvailableCache';
import { InviteInfoQuery } from './@types/InviteInfoQuery';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ErrorBanner } from '../../components/ErrorBanner/ErrorBanner';

const AUTO_JOIN_SEARCH_PARAM = 'auto_join';
const AUTO_JOIN_SEARCH_VALUE = 'true';

const INVITE_INFO_QUERY = gql`
  query InviteInfoQuery($token: String!) {
    inviteInfo(token: $token) {
      botId
      alreadyHasThisBot
      alreadyHasThisWorkspace
      currentUserId
      originalAccountType
      botTitle
      inviterName
      isUsed
      facebookAccountConnected
      workspaceId
      workspaceName
    }
  }
`;

const JOIN_BOT_MUTATION = gql`
  mutation JoinBot($token: String!) {
    acceptInviteToken(token: $token)
  }
`;

export const Invite = (props: RouteComponentProps<{ token: string }>) => {
  const { t } = useSafeTranslation();
  const { history, location } = props;
  const { token } = props.match.params;
  const [isErrorState, setErrorState] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const isAutoJoin =
    searchParams.get(AUTO_JOIN_SEARCH_PARAM) === AUTO_JOIN_SEARCH_VALUE;

  const handleError = (error: Error) => {
    removeGlobalLoader();

    log.warn({ error, msg: 'Error while joining the bot' });

    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message: i18next.t(
          'Invite-string-3944-something-went-wrong-please-try-again-later',
        ),
      },
    });
  };

  const [joinBot, { loading: isJoinLoading }] = useMutation(JOIN_BOT_MUTATION, {
    variables: {
      token,
    },
    onError: handleError,
  });

  const { data, loading, refetch } = useQuery<InviteInfoQuery>(
    INVITE_INFO_QUERY,
    {
      variables: { token },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      onError: handleError,
      onCompleted: async (data) => {
        const botId = data.inviteInfo?.botId;
        const workspaceId = data.inviteInfo?.workspaceId;
        const alreadyHasThisBot = data.inviteInfo?.alreadyHasThisBot;

        const alreadyHasThisWorkspace =
          !!data?.inviteInfo.alreadyHasThisWorkspace;
        const hasAuth = !!data?.inviteInfo.currentUserId;
        const originalAccountType = data?.inviteInfo.originalAccountType;
        const facebookAccountConnected =
          data?.inviteInfo.facebookAccountConnected;

        if (
          originalAccountType === OriginalAccountType.guest &&
          !facebookAccountConnected
        ) {
          history.push({
            pathname: '/logout',
            state: {
              interruptedHref: locationToUrl(location),
            },
          });
          return;
        }

        if (hasAuth) {
          await setFirstSessionDone();
        }

        if (alreadyHasThisBot && botId) {
          redirectToDefaultTab(botId);
          return;
        }

        if (alreadyHasThisWorkspace && workspaceId) {
          history.push('/bots');
          return;
        }

        if (isAutoJoin && (botId || workspaceId)) {
          try {
            const { data } = await joinBot();
            if (data?.acceptInviteToken) {
              if (botId) {
                redirectToDefaultTab(botId);
              } else {
                history.push('/bots');
              }
            }
          } catch (error) {
            log.error({ error });
          }
          return;
        }

        removeGlobalLoader();
      },
    },
  );

  useEffect(() => {
    if (isAutoJoin) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoJoin]);

  if (loading) {
    return null;
  }

  const hasAuth = !!data?.inviteInfo.currentUserId;

  const botTitle = data?.inviteInfo.botTitle;
  const workspaceTitle = data?.inviteInfo.workspaceName;
  const inviterName = data?.inviteInfo.inviterName;

  const workspaceId = data?.inviteInfo.workspaceId;
  const botId = data?.inviteInfo.botId;

  const isUsed = !!data?.inviteInfo.isUsed;
  const isValidInvite = (botId || workspaceId) && !isUsed;
  const isUsedInvite = botId && isUsed;

  const interruptedHrefWithAutoJoin = new URL(window.location.href);

  interruptedHrefWithAutoJoin.searchParams.set(
    AUTO_JOIN_SEARCH_PARAM,
    AUTO_JOIN_SEARCH_VALUE,
  );

  return (
    <AuthLayout
      top={isErrorState ? <ErrorBanner /> : null}
      left={
        isValidInvite ? (
          <div className={css.container}>
            <Bot />

            <Type size="15px" color="baseNormal" className={css.content}>
              {inviterName} {t('pages.MultiSystemAuth.invite.action')}
              <br />
              <TextEllipsis width={300} inline>
                <Type size="24px" color="baseNormal" weight="bold">
                  {workspaceTitle || botTitle}
                </Type>
              </TextEllipsis>
            </Type>

            <div className={css.content}>
              {hasAuth ? (
                <Button
                  disabled={isJoinLoading}
                  intent="primary"
                  fullWidth
                  onClick={async () => {
                    try {
                      const { data } = await joinBot();
                      if (data?.acceptInviteToken) {
                        if (workspaceId) {
                          updateWorkspaceAvailableCache(true);
                          history.push('/bots');
                        } else redirectToDefaultTab(botId!);
                      }
                    } catch (error) {
                      handleError(error as Error);
                    }
                  }}
                >
                  {i18next.t('Invite-JSXText-8078-join')}
                </Button>
              ) : (
                <AuthButtons
                  onError={() => setErrorState(true)}
                  interruptedHref={interruptedHrefWithAutoJoin.toString()}
                  type="invite"
                />
              )}
            </div>
          </div>
        ) : (
          <Invalid isUsedInvite={!!isUsedInvite} />
        )
      }
    />
  );
};
