import { useQuery } from '@apollo/react-hooks';
import { UserAccountStatusQuery } from './@types/UserAccountStatusQuery';
import { USER_ACCOUNT_STATUS_QUERY } from './queries';

export const useAccountStatus = () => {
  const { data: accountStatus, ...queryStatus } =
    useQuery<UserAccountStatusQuery>(USER_ACCOUNT_STATUS_QUERY);

  return {
    status: accountStatus?.me,
    ...queryStatus,
  };
};
