import { getDefaultTab } from '@utils/Routing';

export const getNewUserInterruptedHref = (
  baseHref: string,
  isDefaultInterruptedHref: boolean,
  firstBotId?: string,
) => {
  if (firstBotId && isDefaultInterruptedHref) {
    return getDefaultTab(firstBotId);
  }
  return baseHref;
};
