import { BotTabs, getTabLink, globalHistory } from '@utils/Routing';
import { getRelativeUrl } from '@utils/UrlUtils/getRelativeUrl';
import { getUserState } from './getUserState';

export const afterGuestLoginActions = async (interruptedHref: string = '/') => {
  const { me } = await getUserState();
  const isNewUser = !me.terms_accepted;
  if (!isNewUser) {
    globalHistory.replace(getRelativeUrl(interruptedHref));
    return;
  }
  const firstBotId = me.roles?.[0]?.bot_id;
  globalHistory.replace(getTabLink(BotTabs.firstSession, firstBotId));
};
