import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { ReactComponent as Pic } from './pic.svg';
import * as css from './ErrorBanner.css';

export const ErrorBanner = () => {
  const { t } = useSafeTranslation();

  return (
    <div className={css.container}>
      <div className={css.content}>
        <div>
          <Type weight="bold" size="18px" color="accent4Text">
            {t('pages.MultiSystemAuth.error.title')}
          </Type>
          <br />
          <Type color="accent4Text">
            {t('pages.MultiSystemAuth.error.description')}
          </Type>
        </div>

        <Pic className={css.pic} />
      </div>
    </div>
  );
};
