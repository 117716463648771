import { useCallback } from 'react';
import { GetFbLoginStatusResponse } from '@components/FBPlugins/FbLoginStatus';
import { callFacebookSDK } from '@components/FBPlugins/callFacebookSDK';
import { useRefreshWhatsappData } from '@utils/Data/Whatsapp';
import { useFbSdkStatus } from './useFbSdkStatus';
import { useAdminFeatures } from '@utils/Data/Admin';
import { useBotFeatures } from '@utils/Data/Bot';

const WHATSAPP_EMBEDDED_SIGNUP_PERMISSIONS =
  'business_management,whatsapp_business_management,whatsapp_business_messaging';

interface UseWhatsappLoginArgs {
  botId: string;
}

export const useWhatsappLogin = ({ botId }: UseWhatsappLoginArgs) => {
  const { fbSdkReady, fbSdkError } = useFbSdkStatus();
  const { refetchWhatsappData, loading } = useRefreshWhatsappData(botId);
  const { adminFeatures } = useAdminFeatures();
  const { botFeatures } = useBotFeatures(botId);
  const isBuisnessAppIntegration =
    botFeatures?.whatsapp_business_app_integration;

  const whatsappLogin = useCallback(
    () =>
      new Promise((resolve) => {
        if (fbSdkReady) {
          const extras: Record<string, unknown> = {
            version: adminFeatures?.embedded_sign_up_version || 1,
            feature: 'whatsapp_embedded_signup',
          };
          if (isBuisnessAppIntegration) extras.featureType = 'coexistence';

          callFacebookSDK((FB) => {
            FB.login(
              (response: GetFbLoginStatusResponse) => {
                /**
                 * мы даже не пытаемся использовать полученный `accessToken` вместо этого просто берем `accessToken` юзера
                 */
                refetchWhatsappData();
                resolve(response);
              },
              {
                scope: WHATSAPP_EMBEDDED_SIGNUP_PERMISSIONS,
                extras,
              },
            );
          });
        }
      }),
    [adminFeatures, fbSdkReady, refetchWhatsappData, isBuisnessAppIntegration],
  );

  return {
    whatsappLogin,
    refetchWhatsappData,
    ready: fbSdkReady && !fbSdkError,
    tokenIsUpdating: loading,
  };
};
