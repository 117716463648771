import React, { ReactNode } from 'react';
import { Flex } from '@ui/Flex';
import { Page } from '@ui/Page';
import { useResetMetaForMobile } from '@utils/css/useResetMetaForMobile';
import { useStartIntercom } from '@components/QuickAccessToolbar/useStartIntercom';
import { TosText } from '../components/TosText/TosText';
import logo from './logo.svg';
import * as css from './AuthLayout.css';

export const AuthLayout = ({
  left,
  top,
  leftControlsClassName,
}: {
  left: ReactNode;
  top?: ReactNode;
  leftControlsClassName?: string;
}) => {
  useResetMetaForMobile();
  useStartIntercom();

  return (
    <Page>
      <div className={css.box}>
        <Flex className={css.leftCol} flexDirection="column">
          {top}
          <Flex
            alignItems="center"
            justifyContent="center"
            className={css.content}
          >
            <div className={leftControlsClassName || css.controls}>{left}</div>
          </Flex>

          <div className={css.bottom}>
            <TosText />
          </div>
        </Flex>

        <div className={css.rightCol}>
          <img alt="Chatfuel" className={css.animation} src={logo} />
        </div>
      </div>
    </Page>
  );
};
