import React, { useEffect } from 'react';
import { InitAuthProps } from '@utils/AuthFlow';
import { sendEvent } from '@utils/Analytics';
import { getLocationReferrer } from 'cf-analytics/src/analytics/utils';
import { useSignUpGuest } from '../hooks/useSignUpGuest';
import { Oops } from '../../Oops';
import { AdditionalAuthErrorType } from '@globals';
import { Redirect } from 'react-router-dom';

interface GuestProps extends InitAuthProps {
  reason: string;
  interruptedHref: string;
}

const REDIRECT_TO_SIGN_ERRORS = [
  AdditionalAuthErrorType.existing_guest_id,
  AdditionalAuthErrorType.fb_already_connected_to_guest_id,
];

export const Guest: React.FC<GuestProps> = ({
  reason,
  location,
  interruptedHref,
}) => {
  const { error } = useSignUpGuest({
    location,
    interruptedHref,
  });
  useEffect(() => {
    sendEvent({
      category: 'guest-sign-up',
      action: 'seen',
      propertyBag: {
        referrer: getLocationReferrer(),
        reason,
        interruptedHref,
      },
    });
  }, [interruptedHref, reason]);

  if (
    error &&
    REDIRECT_TO_SIGN_ERRORS.includes(error as AdditionalAuthErrorType)
  ) {
    return <Redirect to="/sign-in" />;
  }

  if (error) {
    return <Oops reason="Error guest login" />;
  }

  return null;
};
