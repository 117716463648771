import React from 'react';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { ButtonLikeLoader } from './ButtonLikeLoader';
import * as css from './OauthButtons.css';

interface FacebookButtonProps {
  title: string;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  isSdkReady?: boolean;
}

export const FacebookButton: React.FC<FacebookButtonProps> = ({
  title,
  loading,
  onClick,
  disabled,
  isSdkReady = true,
}) => {
  if (loading) {
    return <ButtonLikeLoader />;
  }

  return (
    <Button
      disabled={disabled}
      intent="primary"
      className={css.button}
      onClick={onClick}
      data-testid={isSdkReady ? "facebook-sign-button" : "facebook-sign-button-loading-sdk"}
    >
      <Flex alignItems="center" justifyContent="center">
        <Icon icon="facebookLogoInv" color="white" />
        <Spacer factor={1} horizontalFactor={1} />
        {title}
      </Flex>
    </Button>
  );
};
