import React from 'react';
import { Link } from 'react-router-dom';
import { Level, log } from 'cf-common/src/logger';
import { Type } from '@ui/Type';
import { InitAuthProps } from '../../utils/AuthFlow';
import { AuthFlowPage } from '../../modern-ui/Page';
import { ReactComponent as NoMatchIcon } from '../../assets/zero-state/not-found.svg';
import * as css from './NoMatch.css';

interface NoMatchProps {
  history: InitAuthProps['history'];
}

export class NoMatch extends React.Component<NoMatchProps, {}> {
  componentDidMount() {
    log({ msg: 'Mount 404 page', level: Level.warn });
  }

  render() {
    const { history } = this.props;

    return (
      <AuthFlowPage
        render={() => (
          <React.Fragment>
            <NoMatchIcon />
            <div className={css.message}>
              <Type size="24px" weight="semibold">
                {window.i18next.t('NoMatch-JSXText--130-whoops')}
              </Type>
              <br />
              <Type size="24px" weight="semibold">
                {window.i18next.t(
                  'NoMatch-JSXText-4272-the-page-youre-looking-for',
                )}
              </Type>
              <br />
              <Type size="24px" weight="semibold">
                {window.i18next.t('NoMatch-JSXText-2549-does-not-exist')}
              </Type>
            </div>
            <div className={css.guide}>
              <span>{window.i18next.t('NoMatch-JSXText--692-you-can')}</span>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
                className={css.link}
              >
                {window.i18next.t(
                  'NoMatch-JSXText-1407-return-to-the-previous-page',
                )}
              </a>
              <br />
              <span>
                {window.i18next.t('NoMatch-JSXText--114-or-go-to-the')}
              </span>
              <Link to="/" className={css.link}>
                {window.i18next.t('NoMatch-JSXText--775-dashboard')}
              </Link>
            </div>
          </React.Fragment>
        )}
      />
    );
  }
}

export default NoMatch;
