import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { LOCAL_DATA_QUERY } from '@utils/AuthFlow/queries';
import client from '../../../common/services/ApolloService';

const TOKEN_REMOVE_MUTATION = gql`
  mutation TokenRemoveMutation {
    tokenRemove @client
    userIdClear @client
  }
`;

export const removeAuthToken = (initClient?: ApolloClient<InMemoryCache>) =>
  (initClient || client).mutate({
    mutation: TOKEN_REMOVE_MUTATION,
    refetchQueries: [{ query: LOCAL_DATA_QUERY }],
    awaitRefetchQueries: true,
  });
