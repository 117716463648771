import gql from 'graphql-tag';

export const SIGN_UP_EMAIL_MUTATION = gql`
  mutation SignUpEmailMutation($payload: EmailSignUpRequest!) {
    signUpEmail(payload: $payload) {
      id
      access_token
      error
    }
  }
`;

export const SIGN_UP_GUEST_MUTATION = gql`
  mutation SignUpGuestMutation($payload: GuestSignUpRequest!) {
    signUpGuest(payload: $payload) {
      id
      access_token
      error
    }
  }
`;

export const SIGN_IN_EMAIL_MUTATION = gql`
  mutation SignInEmailMutation($payload: EmailSignInRequest!) {
    signInEmail(payload: $payload) {
      id
      access_token
      error
    }
  }
`;

export const PASSWORD_RESET_REQUEST_MUTATION = gql`
  mutation PasswordResetRequestMutation($email: String!) {
    passwordResetRequest(email: $email) {
      error
    }
  }
`;

export const PASSWORD_RESET_CONFIRM_MUTATION = gql`
  mutation PasswordResetConfirmMutation(
    $password: String!
    $recovery_code: String!
  ) {
    passwordResetConfirm(password: $password, recovery_code: $recovery_code) {
      error
      email
    }
  }
`;

export const GET_CF_TOKEN_ON_ACCESS_TOKEN_MUTATION = gql`
  mutation GetCfTokenOnAccessTokenMutation(
    $accessToken: String!
    $queryObjectStringified: String
    $permissionGroup: String
    $authToken: String
  ) {
    generateTokenFromSdk(
      sdkToken: $accessToken
      queryObjectStringified: $queryObjectStringified
      permissionGroup: $permissionGroup
      authToken: $authToken
    ) {
      success
      token
      error_type
    }
  }
`;

export const USER_ACCOUNT_STATUS_QUERY = gql`
  query UserAccountStatusQuery {
    me {
      id
      original_account_type
      verified_account
      facebook_account_connected
      fbPermissions {
        hasMinimalPermissions
        hasAllPermissions
      }
    }
  }
`;

export const GOOGLE_REDIRECT_LINK_QUERY = gql`
  query GoogleRedirectLinkQuery {
    googleRedirectLink
  }
`;

export const CONFIRM_GOOGLE_OAUTH_MUTATION = gql`
  mutation ConfirmGoogleOauthMutation(
    $code: String!
    $query: [AuthQueryParam!]
  ) {
    confirmGoogleOauth(code: $code, query: $query) {
      access_token
      error
    }
  }
`;
