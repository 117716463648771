import { getDefaultTab, globalHistory } from '@utils/Routing';
import { getRelativeUrl } from '@utils/UrlUtils/getRelativeUrl';
import { getNewUserInterruptedHref } from './getNewUserInterruptedHref';
import { getUserState } from './getUserState';
import { getInterruptedPath } from './getInterruptedPath';

export const afterLoginActions = async (interruptedHref: string = '/') => {
  const { me } = await getUserState();
  const isNewUser = !me.terms_accepted;

  if (!isNewUser) {
    globalHistory.replace(getRelativeUrl(interruptedHref));
    return;
  }

  const interruptedPath = getInterruptedPath(interruptedHref);
  const isDefaultInterruptedHref = ['/', '/bots'].includes(interruptedPath);
  const firstBotId = me.roles?.[0]?.bot_id;
  const newUserInterruptedHref = getNewUserInterruptedHref(
    getRelativeUrl(interruptedHref),
    isDefaultInterruptedHref,
    firstBotId,
  );

  let urlToRedirect = '/';

  if (newUserInterruptedHref || !firstBotId) {
    urlToRedirect = newUserInterruptedHref || '/';
  } else if (firstBotId) {
    urlToRedirect = getDefaultTab(firstBotId);
  }

  globalHistory.replace(urlToRedirect);
};
