import React from 'react';
import { Spacer } from '../../../modern-ui/Spacer';
import { FacebookButton } from './Buttons/FacebookButton';
import { GoogleButton } from './Buttons/GoogleButton';
import { useLocation } from 'react-router-dom';
import { useGoogleOauthNavigate } from '../hooks/useGoogleOauthNavigate';
import { useSafeTranslation } from '../../../utils/useSafeTranslation';
import { useFacebookLogin } from '../hooks/useFacebookLogin';
import { acceptGdpr, sendEvent } from 'cf-common/src/analytics';
import { getLocationReferrer } from 'cf-analytics/src/analytics/utils';
import { log } from 'cf-common/src/logger';

export const AuthButtons = ({
  interruptedHref,
  type,
  onError,
}: {
  interruptedHref: string;
  type: 'sign-in' | 'sign-up' | 'invite';
  onError: () => void;
}) => {
  const { t } = useSafeTranslation();
  const location = useLocation();

  const { navigateToGoogleAuth, isLoading: isGoogleLoading } =
    useGoogleOauthNavigate(interruptedHref);

  const { facebookLogin, inProgressFacebookLogin, ready: fbSdkReady } = useFacebookLogin({
    interruptedHref,
    location,
  });

  const sendEvents = (authType: 'google' | 'facebook') => {
    sendEvent({
      category: type,
      action: `click ${authType} ${type.replace('-', ' ')}`,
      propertyBag: {
        referrer: getLocationReferrer(),
      },
    });
    acceptGdpr(true);
  };

  return (
    <>
      <FacebookButton
        isSdkReady={fbSdkReady}
        disabled={isGoogleLoading}
        title={t('pages.MultiSystemAuth.signIn.fb')}
        loading={inProgressFacebookLogin}
        onClick={async () => {
          sendEvents('facebook');
          try {
            await facebookLogin();
          } catch (error) {
            log.error({ error });
            onError();
          }
        }}
      />
      <Spacer factor={2} />
      <GoogleButton
        disabled={inProgressFacebookLogin}
        title={t('pages.MultiSystemAuth.signIn.google')}
        loading={isGoogleLoading}
        onClick={() => {
          sendEvents('google');
          try {
            navigateToGoogleAuth();
          } catch (error) {
            log.error({ error });
            onError();
          }
        }}
      />
    </>
  );
};
