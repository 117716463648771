import { Type } from '@ui/Type';
import { Anchor } from '@ui/Links';
import { sendEvent } from '@utils/Analytics';
import { getLocationReferrer } from 'cf-analytics/src/analytics/utils';
import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './TosText.css';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';
import { sendMessageToWebView } from '@utils/sendMessageToWebView';

export const TosText: React.FC = () => {
  const { t } = useSafeTranslation();
  const { isLiveChatWebView } = useIsLiveChatWebView();

  return (
    <div className={css.tos}>
      <Type size="15px" color="greyDark" align="center" as="div">
        {t('pages.MultiSystemAuth.tos.text')}{' '}
        <Anchor
          href="https://chatfuel.com/files/TermsOfUse.pdf"
          rel="noopener noreferrer"
          target="_blank"
          hideArrow
          className={css.link}
          data-testid="tos-link"
          onClick={(event) => {
            if (isLiveChatWebView) {
              event.preventDefault();
              sendMessageToWebView({ type: 'openTermsOfUse' });
            }

            sendEvent({
              category: 'sign-up',
              action: 'click tos link',
              propertyBag: {
                referrer: getLocationReferrer(),
              },
            });
          }}
        >
          {t('pages.MultiSystemAuth.tos.link')}
        </Anchor>{' '}
        {t('pages.MultiSystemAuth.tos.and')}{' '}
        <Anchor
          href="https://chatfuel.com/files/PrivacyPolicy.pdf"
          rel="noopener noreferrer"
          target="_blank"
          hideArrow
          className={css.link}
          data-testid="tos-link"
          onClick={(event) => {
            if (isLiveChatWebView) {
              event.preventDefault();
              sendMessageToWebView({
                type: 'openURL',
                payload: {
                  url: 'https://chatfuel.com/files/PrivacyPolicy.pdf',
                },
              });
            }

            sendEvent({
              category: 'sign-up',
              action: 'click tos link',
              propertyBag: {
                referrer: getLocationReferrer(),
              },
            });
          }}
        >
          {t('pages.MultiSystemAuth.tos.policy')}
        </Anchor>
      </Type>
    </div>
  );
};
