import { setUserId } from '@utils/UserId';
import { sendEvent } from '@utils/Analytics';
import { Level, log } from 'cf-common/src/logger';
import { getUserState } from './getUserState';

export const sendAfterLoginEvents = async () => {
  const { me, errors } = await getUserState();
  if (me?.id) {
    setUserId(me.id);
    sendEvent(
      {
        category: 'auth',
        action: 'redirect',
      },
      true,
    );
  } else {
    log({
      level: Level.info,
      msg: `Lost user auth event: ${JSON.stringify(me)}, ${JSON.stringify(
        errors,
      )}`,
    });
  }
};
