import React from 'react';
import { Type } from '@ui/Type';
import * as css from './Invalid.css';
import { ReactComponent as ExclamationMarkIcon } from '../../../../../assets/zero-state/exclamation-mark.svg';

interface InvalidProps {
  isUsedInvite?: boolean;
}

export const Invalid: React.FC<InvalidProps> = ({ isUsedInvite }) => (
  <div className={css.container}>
    <ExclamationMarkIcon />
    <div className={css.sadMessage}>
      <Type size="24px" weight="semibold">
        {isUsedInvite
          ? window.i18next.t('Sad-string--128-the-invite-has-already-been-used')
          : window.i18next.t('Sad-string-1945-the-link-is-broken')}
      </Type>
    </div>
    <div className={css.advice}>
      <span>
        {window.i18next.t('Sad-JSXText--112-ask-your-admin-for-a-new-one')}
      </span>
    </div>
  </div>
);
