import gql from 'graphql-tag';
import client from '../../../common/services/ApolloService';
import { UserStateQuery } from './@types/UserStateQuery';

const USER_STATE_QUERY = gql`
  query UserStateQuery {
    me {
      id
      terms_accepted
      email
      roles {
        bot_id
      }
    }
  }
`;

export const getUserState = async () => {
  const { data: userData, errors } = await client.query<UserStateQuery>({
    query: USER_STATE_QUERY,
  });

  return {
    me: userData?.me,
    errors,
  };
};
