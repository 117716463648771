import React, { useEffect, useLayoutEffect } from 'react';
import { Type } from '@ui/Type';
import { Level, log } from 'cf-common/src/logger';
import { removeGlobalLoader } from 'cf-common/src/utils/removeGlobalLoader';
import { AuthFlowPage } from '../../modern-ui/Page';
import { Button, ButtonIntent } from '../../modern-ui/_deprecated/Button';
import { sendEvent } from '../../utils/Analytics';
import { isWhiteLabelDomain } from '../../utils/WhiteLabelUtils';
import { Anchor } from '../../modern-ui/Links';
import { ReactComponent as ExclamationMarkIcon } from '../../assets/zero-state/exclamation-mark.svg';
import * as css from './Oops.css';
import { ResetMetaForMobile } from '@utils/css/ResetMetaForMobile';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';
import noop from 'lodash-es/noop';

const Header: React.FC<{ title: string; subtitle: string }> = ({
  title,
  subtitle,
}) => (
  <div className={css.message}>
    <Type size="24px" weight="semibold">
      {title}
    </Type>
    <br />
    <Type size="24px" weight="semibold">
      {subtitle}
    </Type>
  </div>
);

const Guide: React.FC<{}> = ({ children }) => (
  <div className={css.guide}>{children}</div>
);

const StatusLink: React.FC<{ title: string }> = ({ title }) => (
  <a
    className={css.link}
    href="http://status.chatfuel.com"
    target="_blank"
    rel="noopener noreferrer"
  >
    {title}
  </a>
);

const ReloadButton: React.FC<{ title: string; onClick(): void }> = ({
  title,
  onClick,
}) => (
  <Button intent={ButtonIntent.primary} onClick={onClick}>
    {title}
  </Button>
);

interface OopsProps {
  reason: string;
  renderPage?: () => React.ReactNode;
  errorText?: string;
}

const BaseOops: React.FC<OopsProps> = ({ reason, errorText, renderPage }) => {
  const { isLiveChatWebView } = useIsLiveChatWebView();

  useEffect(() => {
    removeGlobalLoader();
  }, []);

  useEffect(() => {
    const reasonText =
      reason || window.i18next.t('Oops-string--194-unknown-reason');

    log({
      msg: `Mount oops page`,
      data: { errorText, reasonText },
      level: Level.warn,
    });

    sendEvent({
      category: 'error page',
      action: 'seen',
      label: reason,
      propertyBag: {
        source: window.location.href,
      },
    });
  }, [reason, errorText]);

  useLayoutEffect(() => {
    if (!isLiveChatWebView) return noop;
    const resetThemeColor = ResetMetaForMobile.themeColor();
    const resetViewport = ResetMetaForMobile.viewport();
    return () => {
      resetThemeColor();
      resetViewport();
    };
  }, [isLiveChatWebView]);

  const renderDefaultPage = () => (
    <>
      <ExclamationMarkIcon />
      <Header
        title={window.i18next.t('Oops-string--164-yikes')}
        subtitle={window.i18next.t('Oops-string-1985-something-went-wrong')}
      />
      {isWhiteLabelDomain() || isLiveChatWebView ? (
        <Guide>
          <span>
            {window.i18next.t(
              'Oops-JSXText--136-try-reloading-the-page-or-contact-us-for-help',
            )}
          </span>
        </Guide>
      ) : (
        <Guide>
          <span>
            {window.i18next.t(
              'Oops-JSXText-1957-try-reloading-the-page-or-checking',
            )}
          </span>
          <br />
          <StatusLink
            title={window.i18next.t('Oops-string-1356-the-status-page')}
          />
          <span>
            {window.i18next.t('Oops-JSXText-8162-or-email')}{' '}
            <Anchor intent="external" href="mailto:team@chatfuel.com">
              {window.i18next.t('Oops-JSXText--166-team-chatfuel-com')}
            </Anchor>{' '}
            {window.i18next.t('Oops-JSXText--119-for-help')}
          </span>
        </Guide>
      )}
      <ReloadButton
        title={window.i18next.t('Oops-string--185-reload')}
        onClick={() => {
          sendEvent({
            category: 'error page',
            action: 'click',
            label: 'reload',
          });
          window.location.reload();
        }}
      />
    </>
  );

  return (
    <AuthFlowPage
      render={renderPage ?? renderDefaultPage}
      hideHeader={isLiveChatWebView}
    />
  );
};

interface Oops extends React.FC<OopsProps> {
  Button: typeof ReloadButton;
  Guide: typeof Guide;
  Header: typeof Header;
  Icon: typeof ExclamationMarkIcon;
  StatusLink: typeof StatusLink;
}

export const Oops: Oops = Object.assign(BaseOops, {
  Button: ReloadButton,
  Guide,
  Header,
  Icon: ExclamationMarkIcon,
  StatusLink,
});

export default Oops;
