import { useEffect, useState } from 'react';
import { initFbSdkEnvAppId } from '@components/FBPlugins/initFbSdkEnvAppId';

export const useFbSdkStatus = () => {
  const [fbSdkReady, setFbSdkReady] = useState<boolean>(false);
  const [fbSdkError, setFbSdkError] = useState<boolean>(false);

  useEffect(() => {
    initFbSdkEnvAppId()
      .then(() => {
        setFbSdkReady(true);
      })
      .catch(() => {
        setFbSdkError(true);
      });
  }, []);

  return {
    fbSdkReady,
    fbSdkError,
  };
};
