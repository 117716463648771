import nanoid from 'nanoid';
import { useMutation } from '@apollo/react-hooks';
import { Location } from 'history';
import { useEffect } from 'react';
import { useSetUserLocale } from '@utils/Data/Admin/Locale/useSetUserLocale';
import { setAuthToken } from '../utils/setAuthToken';
import { getQueryObjectApPairs } from '../utils/query';
import { sendAfterLoginEvents } from '../utils/sendAfterLoginEvents';
import {
  SignUpGuestMutation,
  SignUpGuestMutationVariables,
} from './@types/SignUpGuestMutation';
import { SIGN_UP_GUEST_MUTATION } from './queries';
import { getClid } from 'cf-common/src/analytics';
import { afterGuestLoginActions } from '../utils/afterGuestLoginActions';
import { LSKey, useLocalStorage } from '@utils/LocalStorage';
import { log } from 'cf-common/src/logger';
import { AuthQueryParam } from '@globals';

interface UseSignUpEmailArgs {
  interruptedHref?: string;
  location: Location;
}

const getSignUpQueryParams = (
  search: string,
  interruptedHref?: string,
): AuthQueryParam[] => {
  return getQueryObjectApPairs(search, interruptedHref);
};

export const useSignUpGuest = ({
  interruptedHref,
  location,
}: UseSignUpEmailArgs) => {
  const setUserLocale = useSetUserLocale();
  const [guestId] = useLocalStorage(LSKey.guestId, () => getClid() || nanoid());
  const [signUpGuestMutation, { data, loading, error }] = useMutation<
    SignUpGuestMutation,
    SignUpGuestMutationVariables
  >(SIGN_UP_GUEST_MUTATION, {
    onCompleted: async (data) => {
      const { access_token, error } = data.signUpGuest;
      if (access_token) {
        await setAuthToken(access_token);
        await sendAfterLoginEvents();
        await setUserLocale();
        await afterGuestLoginActions(interruptedHref);
        return;
      }
      log.warn({ error, msg: 'Error guest login' });
    },
    onError: (error) => {
      log.warn({ error, msg: 'Error guest login' });
    },
    variables: {
      payload: {
        guestId,
        query: getSignUpQueryParams(location.search, interruptedHref),
      },
    },
  });

  useEffect(() => {
    signUpGuestMutation();
  }, [signUpGuestMutation]);

  return {
    accessToken: data?.signUpGuest.access_token,
    loading,
    error: error || data?.signUpGuest.error,
  };
};
