import { GOOGLE_REDIRECT_LINK_QUERY } from './queries';
import { useLazyQuery } from 'react-apollo';
import { GoogleRedirectLinkQuery } from './@types/GoogleRedirectLinkQuery';

export const useGoogleOauthNavigate = (interruptedHref: string | undefined) => {
  const [execute, { loading, error }] = useLazyQuery<GoogleRedirectLinkQuery>(
    GOOGLE_REDIRECT_LINK_QUERY,
    {
      onCompleted: (data) => {
        const url = new URL(data.googleRedirectLink);
        if (interruptedHref) {
          url.searchParams.set('state', interruptedHref);
        }
        window.location.assign(url);
      },
    },
  );

  return {
    navigateToGoogleAuth: () => execute(),
    isError: !!error,
    isLoading: loading,
  };
};
