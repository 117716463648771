import React, { useEffect, useState } from 'react';
import { Flex } from '@ui/Flex';
import { useResetMetaForMobile } from '@utils/css/useResetMetaForMobile';
import { useStartIntercom } from '@components/QuickAccessToolbar/useStartIntercom';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { removeGlobalLoader } from 'cf-common/src/utils/removeGlobalLoader';
import { getLocationReferrer } from 'cf-analytics/src/analytics/utils';
import { sendEvent } from 'cf-common/src/analytics';
import { removeSyntheticLoginFlag } from '@utils/syntheticLogin';
import { removeLoginCookie } from '@utils/loginCookie';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { sendMessageToWebView } from '@utils/sendMessageToWebView';
import { AuthLayout } from '../layout/AuthLayout';
import { AuthButtons } from '../components/AuthButtons';
import { deleteQueryParam, useQueryParams } from '../../../utils/queryParams';
import { ErrorBanner } from '../components/ErrorBanner/ErrorBanner';

interface GenericSignProps {
  type: 'sign-in' | 'sign-up';
  reason: string;
  interruptedHref: string;
}

export const GenericSign: React.FC<GenericSignProps> = ({
  type,
  interruptedHref,
  reason,
}) => {
  useResetMetaForMobile();
  useStartIntercom();
  const { t } = useSafeTranslation();
  const [isErrorState, setErrorState] = useState(false);
  const qs = useQueryParams<{ error?: string }>();

  useEffect(() => {
    if (qs?.error) {
      setErrorState(true);
      deleteQueryParam('error');
    }
  }, [qs]);

  useEffect(() => {
    sendEvent({
      category: type,
      action: 'seen',
      propertyBag: {
        referrer: getLocationReferrer(),
        reason,
        interruptedHref,
      },
    });
  }, [interruptedHref, reason, type]);

  useEffect(() => {
    removeSyntheticLoginFlag();
    removeLoginCookie();
    removeGlobalLoader();
  }, []);

  const { isLiveChatWebView } = useIsLiveChatWebView();

  return (
    <AuthLayout
      top={isErrorState ? <ErrorBanner /> : null}
      left={
        <>
          <Type as="div" size="24px" weight="semibold" align="center">
            {t('pages.MultiSystemAuth.signIn.title')}
          </Type>
          <Spacer factor={5} />
          {isLiveChatWebView ? (
            <Flex justifyContent="center">
              <Button
                onClick={() => {
                  sendMessageToWebView({ type: 'signIn' });
                }}
              >
                {t('pages.MultiSystemAuth.signIn.logInBrowser')}
              </Button>
            </Flex>
          ) : (
            <AuthButtons
              interruptedHref={interruptedHref}
              type={type}
              onError={() => setErrorState(true)}
            />
          )}
        </>
      }
    />
  );
};
