import { useQueryParams } from '../../../utils/queryParams';
import { useMutation } from 'react-apollo';
import { CONFIRM_GOOGLE_OAUTH_MUTATION } from '../hooks/queries';
import { useEffect } from 'react';
import { ConfirmGoogleOauthMutation } from '../hooks/@types/ConfirmGoogleOauthMutation';
import { setAuthToken } from '../utils/setAuthToken';
import { useSetUserLocale } from '../../../utils/Data/Admin/Locale/useSetUserLocale';
import { afterLoginActions } from '../utils/afterLoginActions';
import { log } from 'cf-common/src/logger';
import { useHistory } from 'react-router-dom';

export const GoogleOAuthHandler = () => {
  const { code } = useQueryParams<{ code: string }>();
  const setUserLocale = useSetUserLocale();
  const { error, state } = useQueryParams<{ error?: string; state?: string }>();
  const history = useHistory();

  const [confirmGoogleOauth] = useMutation<ConfirmGoogleOauthMutation>(
    CONFIRM_GOOGLE_OAUTH_MUTATION,
  );

  useEffect(() => {
    if (error) {
      history.push(`/sign-in?error=${error}`);
    }
  }, [error, history]);

  useEffect(() => {
    if (!code) {
      return;
    }

    confirmGoogleOauth({
      variables: {
        code,
      },
    })
      .then(async ({ data }) => {
        if (data?.confirmGoogleOauth.access_token) {
          await setAuthToken(data.confirmGoogleOauth.access_token);
          await setUserLocale();
          await afterLoginActions(state);
        } else {
          log.error({
            msg: 'google-auth',
            error: data?.confirmGoogleOauth.error,
          });
          throw new Error();
        }
      })
      .catch((error) => {
        log.error({
          msg: 'google-auth',
          error,
        });
        history.push('/sign-in?error=something_went_wrong');
      });
  }, [code, state, confirmGoogleOauth, setUserLocale, history]);

  return null;
};
