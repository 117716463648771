import React, { useState } from 'react';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ReactComponent as ConnectImg } from './img.svg';
import { useLocation } from 'react-router-dom';
import { TokenError } from '@globals';
import { useFacebookLogin } from '../../hooks/useFacebookLogin';
import { FacebookButton } from '../../components/Buttons/FacebookButton';
import * as css from './FBBAConnectDialog.css';
import { sendEvent } from 'cf-common/src/analytics';
import { Button } from '../../../../modern-ui/Button';

export const FBBAConnectDialog = ({ onLogin }: { onLogin: () => void }) => {
  const { t } = useSafeTranslation();
  const location = useLocation();
  const [accountConnectError, setAccountConnectError] = useState<string>();
  const [shouldLoginToAnotherFB, setShouldLoginToAnotherFB] = useState(false);
  const {
    facebookLogin,
    ready: fbLoginReady,
    inProgressFacebookLogin,
  } = useFacebookLogin({
    interruptedHref: shouldLoginToAnotherFB ? undefined : window.location.href,
    location,
    ignoreAutologin: shouldLoginToAnotherFB,
    connectAccountOnly: !shouldLoginToAnotherFB,
    onAccountConnected: () => {
      if (shouldLoginToAnotherFB) {
        window.location.assign('/');
      } else {
        onLogin();
      }
    },
    onAccountConnectFailed: (connectAccountError) => {
      switch (connectAccountError) {
        case TokenError.FB_PROFILE_CONFLICT:
          sendEvent({
            category: 'connect fb wa',
            action: 'already signed up',
          });
          setAccountConnectError(
            t('pages.MultiSystemAuth.accountsConflict.error'),
          );
          setShouldLoginToAnotherFB(true);
          break;
        default:
          setAccountConnectError(
            t('Auth-string-5848-there-was-an-error-logging-you-into-facebook'),
          );
      }
    },
  });

  return (
    <div className={css.container}>
      {!accountConnectError && <ConnectImg />}

      <Type as="div" size="15px" color="baseNormal" className={css.content}>
        {accountConnectError ||
          t('pages.MultiSystemAuth.fbConnect.description')}
      </Type>

      <div className={css.action}>
        {accountConnectError ? (
          <Button
            loading={!fbLoginReady && inProgressFacebookLogin}
            intent="secondary"
            onClick={() => facebookLogin()}
            data-testid="facebook-pages__setup-failed__contact-support-button"
          >
            {t('pages.MultiSystemAuth.accountsConflict.login')}
          </Button>
        ) : (
          <FacebookButton
            loading={!fbLoginReady || inProgressFacebookLogin}
            title={t('pages.MultiSystemAuth.signIn.fb')}
            onClick={() => facebookLogin()}
          />
        )}
      </div>
    </div>
  );
};
