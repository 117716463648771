export const getInterruptedPath = (interruptedHref: string) => {
  let interruptedPath;

  try {
    interruptedPath = new URL(interruptedHref).pathname;
  } catch (e) {
    interruptedPath = interruptedHref;
  }

  return interruptedPath;
};
