import { searchStringToObject } from '@utils/UrlUtils';
import { omit } from 'ramda';
import { log } from 'cf-common/src/logger';
import { AuthQueryParam } from '@globals';

type QueryParams = Record<string, string | null | undefined>;

export const getQueryObject = (
  search: string,
  interruptedHref?: string,
  omitFields: string[] = [],
  query?: QueryParams,
) => {
  let interruptedHrefQuery;

  if (interruptedHref) {
    const { search: interruptedHrefSearch } = new URL(
      interruptedHref,
      document.baseURI,
    );
    interruptedHrefQuery = searchStringToObject(interruptedHrefSearch);
  }

  const loginQuery = searchStringToObject(search);

  const queryObject: QueryParams = {
    ...interruptedHrefQuery,
    ...loginQuery,
    ...query,
  };

  if (!queryObject.ref && document.referrer) {
    queryObject.ref = document.referrer;
  }

  return omit(omitFields, queryObject);
};

export const getQueryObjectStringified = (
  search: string,
  interruptedHref?: string,
  omitFields: string[] = [],
  query?: QueryParams,
) => {
  let queryObjectStringified = '';
  try {
    queryObjectStringified = JSON.stringify(
      omit(
        omitFields,
        getQueryObject(search, interruptedHref, omitFields, query),
      ),
    );
  } catch (error) {
    log.verbose({ error, msg: 'Can not stringify query' });
  }
  return queryObjectStringified;
};

export const getQueryObjectApPairs = (
  search: string,
  interruptedHref?: string,
  omitFields: string[] = [],
  query?: QueryParams,
) =>
  Object.entries(
    getQueryObject(search, interruptedHref, omitFields, query),
  ).map(([key, value]) => ({ key, value })) as AuthQueryParam[];
